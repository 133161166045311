<template>
    <div class="products-main">
        <div class="page-title">
            <h3>Товары</h3>
        </div>
        <section >
            <Loader v-if="loading"/>
            <div class="row" v-else>

                <CreateProduct :categories="categories"/>

                <EditProducts v-if="false"/>

                <h2 v-else> Создайте новый товар</h2>

            </div>
        </section>
    </div>
</template>

<script>
    import CreateProduct from "../../components/admin/CreateProduct";
    import EditProducts from "../../components/admin/EditProducts";

    export default {
        name: 'products',
        components:{
            EditProducts, CreateProduct
        },
        data: () => ({
            updateCount : 0,
            loading: true,
            categories: []

        }),
        async mounted(){
            //загрузка категорий в элемент
            //загрузка категорий в элемент
            this.categories = await this.$store.dispatch('fetchCategories');
            this.loading = false;


        },

        methods:{
            //слушаем изменения в дочернем элементе используя одноименные функции



        }

    }
</script>

