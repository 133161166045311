<template>
	<div class="col s12 m12 l12 xl12 category">
		<div>
			<div class=" page-subtitle">
				<h3>Создать товар</h3>
			</div>

			<form class="product" @submit.prevent="submitHandler">
				<div class="row">
					<div class="product-container col s12 m12 l4 xl4 ">
						<div class="input-field ">
							<input
								id="title"
								type="text"
								v-model="title"
								:class="{ invalid: $v.title.$dirty && !$v.title.required }"
							/>

							<label for="title">Название товара </label>

							<span
								v-if="$v.title.$dirty && !$v.title.required"
								class="helper-text invalid red-text"
							>
								Введите название
							</span>
						</div>

						<div class="input-field ">
							<input
								id="price"
								type="text"
								v-model="price"
								:class="{ invalid: $v.price.$dirty && !$v.price.required }"
							/>

							<label for="price">Цена товара </label>

							<span
								v-if="$v.price.$dirty && !$v.price.required"
								class="helper-text invalid red-text"
							>
								Введите цену
							</span>
						</div>

						<div class="input-field">
							<input
								id="amount"
								type="text"
								v-model="amount"
								:class="{ invalid: $v.amount.$dirty && !$v.amount.required }"
							/>

							<label for="amount">количество товара </label>

							<span
								v-if="$v.amount.$dirty && !$v.amount.required"
								class="helper-text invalid red-text"
							>
								Введите доступное количество товара
							</span>
						</div>

						<div class="input-field">
							<select ref="select" v-model="current">
								<option
									v-for="c in categories"
									:key="c.id"
									:value="{ Id: c.id, Path: c.path }"
								>
									<span class="black-text text-lighten-1"
										>Категория "{{ c.title }}"</span
									>
								</option>
							</select>

							<label>Выберите родительскую категорию</label>
						</div>

						<div class="input-field">
							<div class="row  row-chips">
								<div v-for="s in subCats" :key="s.id">
									<div class="chip">
										<label :for="s.id">
											<input
												:value="{ Id: s.id, Path: s.path }"
												:id="s.id"
												type="checkbox"
												v-model="pickedSubCats"
											/>
											<span> {{ s.title }} </span>
										</label>

										<i
											class="close material-icons"
											@click="pickedSubCats.splice(s.id, 1)"
											>close</i
										>
									</div>
								</div>
								<span> выбрано: {{ pickedSubCats }}</span>
							</div>
						</div>
					</div>

					<div class="photo row  col s12 m12 l8 xl8">
						<div class="img-product-example  row">
							<h4>Добавить изображение товара</h4>
							<div
								class="main-img card-panel container white center col s12 m12 l6 xl6 lighten-5 z-depth-4"
								:class="{ invalid: !$v.mainImage.required }"
							>
								<input
									style="display: none"
									ref="mainFileInput"
									@change="onFileMainChange"
									type="file"
									accept="image/*"
								/>
								<img
									v-if="mainImageSrc"
									class="responsive-img maimImg"
									style="max-height: 25rem;   "
									:src="mainImageSrc"
								/>

								<button
									v-if="!mainImageSrc"
									@click="handleMainImage"
									type="button"
									class="btn flat btn-block  grey lighten-2 white-text"
									style="min-height: 17rem;border-radius: 20px;!important;"
								>
									<i class="material-icons" style="font-size: 4rem">photo</i>
									<span
										class="flow-text"
										style="font-weight: 500; font-size: 1.3rem"
										>Главное изображение
									</span>
								</button>

								<button
									v-else
									class="btn-block  btn red white-text waves-red"
									type="button"
									@click="removeMainImage"
								>
									<span style="margin-right: 1rem;">
										Удалить
									</span>
									<i class="material-icons center white-text">delete</i>
								</button>
							</div>

							<span
								v-if="!$v.mainImage.required"
								class="helper-text invalid red-text"
								style="font-size: 1rem;"
							>
								Выберите главную фотографию товара!
							</span>

							<div class="other-img  container center col s12 m12 l6 xl6  ">
								<div class="center">
									<button
										@click="handleOtherImages"
										type="button"
										class="btn flat  blue waves-effect waves-light lighten-2 white-text "
										style="margin-top: 3.5rem; height: 4rem; border-radius: 20px;"
									>
										<span
											style="margin-right: 20px; position:relative; bottom: 0.4rem; font-size: 1.3rem;"
										>
											Доп. картинки
										</span>
										<i
											class="material-icons"
											style="font-size: 2.7rem; position:relative; top: 0.4rem; "
											>add_circle</i
										>
									</button>

									<input
										multiple
										style="display: none"
										ref="otherImageInput"
										@change="onOtherImagesChange"
										type="file"
										accept="image/*"
									/>
								</div>

								<div
									v-for="image in otherImagesPreview"
									class="card-panel secondary-img  white lighten-5 z-depth-1 "
								>
									<img :src="image" class="responsive-img" alt="" />
									<span>{{ image.name }}</span>

									<button
										class="btn red waves-red lighten-1 right "
										@click="
											otherImagesPreview.splice(image, 1) &&
												otherImages.splice(image, 1)
										"
									>
										<i class="material-icons center white-text">delete</i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="decription-row">
						<h4>Описание товара</h4>

						<VueEditor
							id="description"
							v-model="description"
							:class="{
								invalid: $v.description.$dirty && !$v.description.required,
							}"
						/>

						<span
							v-if="$v.description.$dirty && !$v.description.required"
							class="helper-text invalid red-text"
						>
							Заполните описание товара
						</span>
					</div>
				</div>

				<button
					:class="{ disabled: pending }"
					class=" btn category-btn blue waves-effect waves-light col s4 m4 l4 xl4"
					@click.prevent="submitHandler"
				>
					Создать
					<i class="material-icons right">send</i>
				</button>
			</form>
		</div>
	</div>
</template>

<style scoped lang="less">
.product {
	margin: 5rem 1rem 15rem 1rem;
}
.product-container {
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.photo {
}
.maimImg {
	height: 25rem;
	object-fit: contain;
}
.card-panel {
	display: block;
	padding: 0;
	margin: 50px 0 50px 0;
	border-radius: 20px;
	button {
		border-radius: 0px 0px 20px 20px;
	}

	button,
	img {
		display: flex;

		width: 100%;
		align-items: center;
		justify-content: center;

		i,
		span {
			align-content: center;
			display: flex;
			-webkit-flex-direction: row;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
		}
		i {
			margin-right: 20px;
		}
	}
}
.secondary-img {
	border-radius: 20px 20px 20px 20px;
	margin: 50px 0 50px 7rem;
	width: 16rem;
	height: 15rem;

	img {
		border-radius: 20px 0px 0 0px;
		object-fit: contain;
		width: 16rem;
		height: 14rem;
	}
}

input {
	border-bottom: 2px solid #2196f3 !important;
}
</style>

<script>
import { VueEditor } from 'vue2-editor';
import { required, minValue } from 'vuelidate/lib/validators';
export default {
	name: 'create-product',
	components: { VueEditor },

	props: {
		categories: {
			type: Array,
			required: true,
		},
	},
	data: () => ({
		pending: false,
		select: null,
		loading: true,
		current: {},

		subCats: [],
		images: [],
		subCatId: null,
		parentCategoryPath: '',
		subcategoriesPaths: '',

		mainImage: null,
		mainImageSrc: '',
		mainImageExt: '',

		otherImagesSrc: '',
		otherImagesExt: '',
		otherImages: [],
		otherImagesPreview: [],

		title: 'Товар №1',
		description: 'Описание',
		amount: 100,
		price: 10000,
		checkbox: null,
		pickedSubCats: [],
	}),

	validations: {
		title: { required },
		description: { required },
		amount: { minValue: minValue(1) },
		price: { minValue: minValue(10) },
		pickedSubCats: { required },
		mainImage: { required },
		otherImages: { required },
	},

	//устанавливаем первоначальные значения в селект
	watch: {
		current(categoryId) {
			this.catId = categoryId;

			try {
				const { title, path, id, subcategories } = this.categories.find(
					(cat) => cat.id === categoryId.Id,
				);
				this.subCats = Object.keys(subcategories).map((key) => ({
					...subcategories[key],
					id: key,
				}));
			} catch (e) {}
		},
	},

	//устанавливаем первоначальные значения в селект
	created() {
		const { id, path } = this.categories[0];
		this.current.Id = id;
		this.current.Path = path;
	},

	async mounted() {
		this.select = this.M.FormSelect.init(this.$refs.select);
		this.M.updateTextFields();
	},

	methods: {
		handleMainImage() {
			this.$refs.mainFileInput.click();
		},

		removeMainImage() {
			this.mainImage = null;
			this.mainImageSrc = '';
		},

		handleOtherImages() {
			this.$refs.otherImageInput.click();
		},

		async onOtherImagesChange(event) {
			if (event.target.files[0]) {
				for (let i = 0; i < this.$refs.otherImageInput.files.length; i++) {
					await this.otherImages.push(this.$refs.otherImageInput.files[i]);

					const file = event.target.files[i];
					const reader = new FileReader();
					reader.onload = (e) => {
						this.otherImagesPreview.push(reader.result);
					};
					reader.readAsDataURL(file);
					// this.otherImagesPreview.push(file);
					// console.log('this.otherImagesPreview', this.otherImagesPreview)
				}
			}
		},

		async onFileMainChange(event) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = (e) => {
				this.mainImageSrc = reader.result;
			};
			reader.readAsDataURL(file);
			this.mainImage = await file;
		},

		async submitHandler() {
			if (!this.mainImage) {
				this.$message('Добавьте главную фотографию товара');
			}
			if (!this.otherImages) {
				this.$message('Добавьте дополнительные фотографии товара');
			}

			if (this.$v.$invalid && !this.mainImage && !this.otherImages.name) {
				this.$v.$touch();

				return;
			}
			try {
				this.pending = true;
				const productData = {
					title: this.title,
					amount: this.amount,
					price: this.price,
					description: this.description,
					parentCategory: this.current,
					subCategories: this.pickedSubCats,
					mainImage: this.mainImage,
					otherImages: this.otherImages,
				};
				const product = await this.$store.dispatch(
					'createProduct',
					productData,
				);

				console.log('___________END PRODUCT CREATION ___________ \n', product);

				this.title = '';
				this.amount = '';
				this.price = '';
				this.description = '';

				this.pickedSubCats = [];
				this.mainImageSrc = '';
				this.mainImage = null;
				this.otherImages = [];
				this.otherImagesPreview = [];

				this.$v.$reset();
				this.$message('Товар успешно создан');
				this.pending = false;
				//this.$emit('productCreated', product)
			} catch (e) {}
		},
	},

	destroyed() {
		if (this.select && this.select.destroy) {
			this.select.destroy;
		}
	},
};
</script>
